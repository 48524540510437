import { useCallback, useState } from 'react';

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => {
    setState(previousState => !previousState);
  }, []);

  const close = useCallback(() => {
    setState(false);
  }, []);

  const open = useCallback(() => {
    setState(true);
  }, []);

  return [state, toggle, close, open];
};

export default useToggle;
