import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import styled, { keyframes, createGlobalStyle } from 'styled-components';

import mq from '@mq';
import { appear, cover } from '@mixins';
import { fontL, fontXl } from '@fonts';

const SplashScreen = () => {
  const [hidden, setHidden] = useState(false);
  const timeout = useRef();

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setHidden(true);
    }, 2000);

    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  const hide = useCallback(() => {
    setHidden(true);
    clearTimeout(timeout.current);
  }, []);

  return (
    <>
      {!hidden && <BodyScrollLock />}
      <Container onClick={hide} hidden={hidden}>
        <Title>Studio Linda Ehrl</Title>
      </Container>
    </>
  );
};

const BodyScrollLock = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Container = styled.div`
  ${cover};
  position: fixed;
  z-index: 4;
  display: grid;
  height: var(--viewport-height);
  align-items: center;
  text-align: center;
  background-color: var(--color-white);
  transition:
    opacity 1s,
    visibility 1s;
  cursor: pointer;
  padding-left: 2rem;
  padding-right: 2rem;

  opacity: ${props => (props.hidden ? 0 : 1)};
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const Title = styled.div`
  --motion-delay: 0.5s;
  ${appear};
  ${fontL};
  color: var(--color-grey);

  ${mq.small} {
    ${fontXl};
  }
`;

export default SplashScreen;
