import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { grid, center } from '@mixins';
import mq, { mqdown, print } from '@mq';
import Link from '@core/Link';

import useToggle from '@hooks/useToggle';
import useOutsideClick from '@hooks/useOutsideClick';
import { globalHistory } from '@reach/router';

const Navigation = () => {
  const [expanded, toggleExpanded, close] = useToggle(false);
  const ref = useOutsideClick(close);

  // const navigateAndCollapse = useCallback(
  //   ({ to }) => {
  //     navigate(to);
  //     close();
  //   },
  //   [close]
  // );

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          close();
        }
      }),
    [close]
  );

  return (
    <Container ref={ref}>
      <Home to="/">Studio Linda Ehrl</Home>
      <Links $expanded={expanded}>
        <li>
          <Link to="/projects">All projects</Link>
        </li>
        <li>
          <Link to="/design">Design</Link>
        </li>
        <li>
          <Link to="/styling">Styling</Link>
        </li>
        <li>
          <Link to="/digital">Digital</Link>
        </li>
        <li>
          <Link to="/moodboards">Moodboards</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </Links>
      <About to="/about">About</About>
      <MenuButton onClick={toggleExpanded} />
    </Container>
  );
};

const Container = styled.nav`
  ${grid};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  height: var(--navigation-height);
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  z-index: 2;
  user-select: none;

  ${print} {
    display: none;
  }
`;

const Home = styled(Link)`
  grid-column: 1 / 5;
  justify-self: start;

  ${mq.medium} {
    grid-column: 1 / 3;
  }
`;

const About = styled(Link)`
  grid-column: -3 / -1;
  justify-self: end;

  ${mqdown.medium} {
    display: none;
  }
`;

const Links = styled.ul`
  display: grid;

  ${mqdown.medium} {
    position: absolute;
    top: var(--navigation-height);
    left: 0;
    right: 0;

    padding-left: var(--gutter);
    padding-right: var(--gutter);
    padding-bottom: 3rem;
    padding-top: 1.25rem;

    justify-items: end;
    grid-auto-flow: row;

    background-color: var(--color-white);
    line-height: 1.83;
    z-index: -1;
    isolation: isolate;
    transform: translate3d(0, ${props => (props.$expanded ? '0' : '-120%')}, 0);
    transition: var(--motion-quadruple) ease-out;
  }

  ${mq.medium} {
    grid-column: 3 / -3;
    justify-self: center;
    grid-auto-flow: column;
    column-gap: 1.5rem;
  }

  li:last-child {
    ${mq.medium} {
      display: none;
    }
  }

  .link-active {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      left: 0;
      right: 0;
      border-bottom: 1px solid currentColor;
    }
  }
`;

const MenuButton = styled.div`
  ${center};
  flex-direction: row;
  gap: 0.25rem;
  grid-column: -3 / -1;
  padding: 0.4rem;
  justify-self: end;
  cursor: pointer;
  margin-right: -0.4rem;

  &:after {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--color-text);
    border-radius: 50%;
  }

  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--color-text);
    border-radius: 50%;
  }

  ${mq.medium} {
    display: none;
  }
`;

export default Navigation;
