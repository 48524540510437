import React from 'react';
import styled, { css } from 'styled-components';
// import { useStaticQuery, graphql } from 'gatsby';

// import { fontLooseM } from '@fonts';
import { grid } from '@mixins';
import mq, { mqdown, print } from '@mq';

import Link from '@core/Link';

const Footer = () => (
  <Container>
    <div>
      <Link to="mailto:contact@lindaehrl.com">contact@lindaehrl.com</Link>
      <div>Brunnenstraße</div>
      <div>10119 Berlin</div>
    </div>
    <div>
      <div>
        <Link to="/impressum">Imprint</Link>
      </div>
      <div>{new Date().getFullYear()}</div>
      {/* <div>
        <Link to="/moodboards-hidden">Moodboards(hidden)</Link>
      </div> */}
      {/* <div>
        <Link to="/tags">Tags</Link>
      </div> */}
    </div>
  </Container>
);

const Container = styled.footer`
  ${grid};

  padding-top: 0.75rem;
  padding-bottom: 6.25rem;
  position: sticky;
  bottom: 0;

  > div {
    grid-column-end: span 3;

    ${mq.small} {
      grid-column-end: span 6;
    }
  }

  ${mqdown.small} {
    > div {
      &:nth-child(2) {
        text-align: right;
      }
    }
  }

  ${mq.small} {
    padding-top: 1.5rem;
  }

  ${print} {
    display: none;
  }
`;

export default Footer;
