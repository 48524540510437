import { createGlobalStyle } from 'styled-components';

import { fontXs, fontM, fontL } from '@fonts';
import { layoutWrapper } from '@mixins';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    line-height: 1.25;
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-family);
    /* height: 100%; */
    /* width: 100%; */
    
    -webkit-font-smoothing: antialiased;
    /* ms-overflow-style: none; */
    /* scrollbar-width: none; */
  }

  /* ::-webkit-scrollbar {
    display: none;
  } */

  main, footer, nav {
    ${fontXs};
  }

  main {
    position: relative;
    z-index: 1;
    background-color: var(--color-background);
    padding-bottom: 15rem;
    border-bottom: 1px solid var(--color-black);
  }

  h1 {
    ${fontL};
  }

  h2 {
    ${fontL};
  }

  h3 {
    ${fontM};
  }

  section {
    ${layoutWrapper};
  }

  @page {
    margin: 1.15cm;
  }
`;

export default Base;
