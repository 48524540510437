import React, { useEffect, useState } from 'react';
import GlobalStyles from '@styles/GlobalStyles';

import Footer from '@core/Footer';
import Navigation from '@core/Navigation';
import SplashScreen from '@components/SplashScreen';
import { globalHistory } from '@reach/router';
import { createGlobalStyle } from 'styled-components';

const Layout = ({ children, location, pageContext: { isProjectPage } }) => {
  const [blockHover, setBlockHover] = useState(false);
  const isTagsPage = location.pathname.includes('/tags');

  useEffect(() => {
    let timeout;
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setBlockHover(true);
        timeout = setTimeout(() => {
          setBlockHover(false);
        }, 500);
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {blockHover && <BlockHover />}
      <GlobalStyles />
      <Navigation />
      {children}
      {!isProjectPage && <Footer />}
      {!isTagsPage && <SplashScreen />}
    </>
  );
};

const BlockHover = createGlobalStyle`
  body {
    pointer-events: none;
  }
`;

export default Layout;
