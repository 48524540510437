import { css } from 'styled-components';

const fontXs = css`
  font: var(--font-xs);
  letter-spacing: var(--letter-spacing-xs);
`;

const fontS = css`
  font: var(--font-s);
`;

const fontM = css`
  font: var(--font-m);
  letter-spacing: var(--letter-spacing-m);
`;

const fontL = css`
  font: var(--font-l);
  letter-spacing: var(--letter-spacing-l);
`;

const fontXl = css`
  font: var(--font-xl);
`;

const fontMonospace = css`
  font: var(--font-monospace);
`;

const fontButton = css`
  font: var(--font-button);
`;

export { fontXs, fontS, fontM, fontL, fontXl, fontMonospace, fontButton };
