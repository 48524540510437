import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-grey: #9C9C9C;
    --color-text: var(--color-black);
    --color-background: var(--color-white);

    --gutter: 0.75rem; //12
    --grid-gap: 0.5rem; //8
    --grid-column-count: 6;

    ${mq.small} {
      --gutter: 1.25rem; //20
      --grid-gap: 0.625rem; //10
      --grid-column-count: 12;
    }

    ${mq.medium} {
      --gutter: 2.5rem; //40
      --grid-gap: 1.25rem; //20
    }

    --navigation-height: 2.5rem; //40

    --font-family: 'futura-pt', sans-serif;
    --font-family-monospace: 'ibm-plex-mono', monospace;

    
    --font-xs: 1.125rem/ 1.27 var(--font-family); //18
    --letter-spacing-xs: 0.01em;
    
    --font-s: 1.375rem / 1.27 var(--font-family); //22
    
    --font-m: 1.6875rem / 1.296 var(--font-family); //27
    --letter-spacing-m: 0.05em;
    
    --font-l: 300 2.25rem/1.27 var(--font-family); //36 light
    --letter-spacing-l: 0.05em;

    --font-xl: 300 5rem/1.27 var(--font-family); //80 light
    
    --font-monospace: 0.875rem/2.14 var(--font-family-monospace); //18 monospace
    --font-button: 0.625rem/1.3 var(--font-family-monospace); //10 monospace
    
    --motion-half: 50ms;
    --motion: 100ms;
    --motion-double: 200ms;
    --motion-triple: 300ms;
    --motion-quadruple: 400ms;
    --motion-quintuple: 500ms;
  }
`;

export default Vars;
